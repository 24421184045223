import React, { Component, Suspense } from 'react';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {Usuario} from '../services/Usuario'
import Principal from '../Pages/Principal';
import ListadoAlumnos from '../Pages/ListadoAlumnos';
import Periodos from '../Pages/Periodos';
import Consolidado from '../Pages/Consolidado';
import ConsolidadoResumen from '../Pages/ConsolidadoResumen';
import NavBar from '../components/NavBar/NavBar';
import {GetData} from '../services/GetData';
import Moment from 'moment';
import 'moment/locale/es';
import Docentes from '../Pages/Docentes';
import GradosDocentes from '../Pages/GradosDocentes';
import NotasNivelacion from '../Pages/NotasNivelacion';
import NotasFinalPeriodo from '../Pages/NotasFinalPeriodo';

class Home extends Component {



    constructor(props){
        
        super(props);
        this.state = {
            usuario_session: Usuario(),
            periodo_actual : "",
            anio_actual: "",
            fecha_cierre_p: "",
            fecha_examenf_p: "",
            dias_faltantes_cierre: 0,
            p_cerrado: false,
            bar_info_periodo: false,
            num_alumnos_matr: 0,
            num_alumnos_retir: 0,
            num_docentes: 0,
            ultima_actualizacion: "",
            loading_datos_matr: false

        }

        this.obtener_periodo_actual = this.obtener_periodo_actual.bind(this);
        this.dias_faltantes = this.dias_faltantes.bind(this);
        this.obtener_num_estudiantes = this.obtener_num_estudiantes.bind(this);
        this.fun_estudiante_mora = this.fun_estudiante_mora.bind(this);
    }

    obtener_num_estudiantes(){
      this.setState({loading_datos_matr: true});
      GetData('matriculas/numestudiantes/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
            
            this.setState({
                num_alumnos_matr: responseJSON.numestudiantes,
                num_alumnos_retir: responseJSON.num_retirados,
                num_docentes: responseJSON.num_docentes,
                loading_datos_matr: false
              })
        }  
      });

    }

    fun_estudiante_mora(){
      
      GetData('matriculas/ultimactalizacion/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
           let update = responseJSON.update;  
         
          this.setState({
            ultima_actualizacion : update
          });
        }
      });
    }     

    obtener_periodo_actual(){
        GetData('periodos/actual/', this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              
            //let fecha_cierre = Moment(responseJSON.fecha_cierre).format('DD/MMM/YYYY');
            let fecha_cierre = responseJSON.fecha_cierre;
            
            

            this.setState({
              periodo_actual :  responseJSON.periodo,
              anio_actual : responseJSON.anio_actual,
              fecha_cierre_p : fecha_cierre,
              fecha_examenf_p : responseJSON.fecha_examenf
            }, () => {
               this.dias_faltantes();
            
            })
          }
        });
  }

  dias_faltantes(){
    let hoy = Moment().format('YYYY-MM-DD');
    
    if (this.state.fecha_cierre_p >= hoy)  {
        this.setState({
          dias_faltantes_cierre: Moment(this.state.fecha_cierre_p, "YYYYMMDD").endOf('day').fromNow(),
          bar_info_periodo: true
        })
        return;
    }else{   
      this.setState({
        p_cerrado: true,
        dias_faltantes_cierre: "Período Cerrado",
        bar_info_periodo: true
      }) 
      return;     
    }    
  }

    cerrar_session = () => {
        sessionStorage.clear();
        this.setState({usuario_session: Usuario()});
    }

    render() {
    
    

        if (!this.state.usuario_session) {
            return (<Redirect to={'/login'} />)
        }
    
        if (this.state.usuarios_inactivo){
           return (<Redirect to={'/paginainactivo'} />)
        }
    
        return (
          
              
    
        <div >
              <NavBar datosusuario={this.state.usuario_session} cerrarsession={this.cerrar_session.bind(this)} />              
    
              <Suspense fallback={<><div className="logoapp"></div><div className="loading"></div></>}>
                    <Route path='/home' exact
                      render={(props) => <Principal {...this.state}  />}
                    />
                    <Route path='/home/consolidado/:idGrado/:idGrupo' component={Consolidado} exact />      
                    <Route path='/home/consolidadoresumen/:idGrado/:idGrupo' component={ConsolidadoResumen} exact />      
                    
                    <Route path='/home/listadoalumnos/:idGrado/:idGrupo' component={ListadoAlumnos} exact /> 
                    {this.state.usuario_session.tipo_usuario == 0 &&
                      <>
                        <Route path='/home/docentes/' component={Docentes} exact /> 
                        <Route path='/home/gradosdocentes/:idDocente' component={GradosDocentes} exact /> 
                        <Route path='/home/asignaturasgrado/:idDocente/:idGrado' component={GradosDocentes} exact /> 
                        <Route path='/home/periodos' component={Periodos} exact />      
                        <Route path='/home/nivelaciones' component={NotasNivelacion} exact />      
                        <Route path='/home/notafinalp' component={NotasFinalPeriodo} exact />      
                      </>
                    }
              </Suspense>          
              
        </div>
       
    
        );
      }
    
      componentDidMount() {
        this.obtener_periodo_actual();
        this.obtener_num_estudiantes();
        
      }  
    
    }
    
    export default Home;