import React from 'react'
import {  Button, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/styles';
import { AssignmentInd, VpnKey } from '@material-ui/icons';


const ItemDocente = ({listaDocentes, crearUsuario, irAGrados}) => {

    const StyledTableCell = withStyles((theme) => ({       

        body: {
          fontSize: 14,
        },
      }))(TableCell);

      const StyledTableRow = withStyles((theme) => ({
        root: {
          
        },
      }))(TableRow);

      

  return (
    <>
     {listaDocentes.map((docente) => (
            <StyledTableRow key={docente.id_docente} className="TextoTabla">
              <StyledTableCell align="left">{docente.id_docente}</StyledTableCell>
              <StyledTableCell align="left">{docente.nombres_apellidos_doc}</StyledTableCell>
              <StyledTableCell align="left">{docente.telefonos_doc}</StyledTableCell>
              <StyledTableCell align="left">{docente.email_doc }</StyledTableCell>
              <StyledTableCell align="center">
                
                   {/*  <Button variant="outlined" onClick={()=> crearUsuario(docente.id_docente) }><AssignmentInd  /></Button> */} {/* datos del docente */}
                
                {/*   <Button variant="outlined"  component={Link} to={`/home/gradosdocentes/${docente.id_docente}`} ><ListAlt  /></Button> */} {/* grados */}
                
                  <Button variant="outlined" onClick={()=> crearUsuario(docente.id_docente) }><VpnKey  /></Button> {/* accesos */}
                
              </StyledTableCell>
            </StyledTableRow>
          ))}
    </>
  )
}

export default ItemDocente