import React from 'react';

import Container from '@material-ui/core/Container';

import BardInfo from './Includes/BarInfo/BardInfo'
import Divider from '@material-ui/core/Divider';
import Grados from '../Pages/Includes/Grados/Grados';

const Principal = (props) => { 

          
        // let checked = false;
     
         return (<>
                    <React.Fragment>
                    
                        <Container maxWidth="lg">
                            

                            
                            <BardInfo {...props}  />
                            <Divider />
                       
                            <Grados {...props} />

                        </Container>
                    </React.Fragment>
         </>);

}

export default Principal;