

export function PostData(type, userData){    
    
    
    const carp_api = sessionStorage.getItem('url_api_txt');  
    
    const URL_SERVICIOS = "http://inscandelaria.com/"+ carp_api+ "/index.php/";


    const url_api = URL_SERVICIOS.replace("$api",carp_api);

    return new Promise((resolve, reject) => {
        fetch(url_api+type,{
            method: 'POST',
            body: JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            console.log("Error " + error);
           userData.error_login =  true;
           reject(error);
           
        })
    })    
}