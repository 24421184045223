import React, { useEffect } from 'react';
import Routes from './routes';

function App() {
  
  useEffect(() => {
    fetch("urlapi.txt")
      .then((response) => response.json())
      .then((dato) => {
          console.log(dato.url)
          sessionStorage.setItem('url_api_txt',dato.url);

      });
  }, []);

  return (
    <Routes />
  );
}

export default App;
