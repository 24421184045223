

export function GetData(type){    

    const carp_api = sessionStorage.getItem('url_api_txt');  
    const URL_SERVICIOS = "http://inscandelaria.com/"+ carp_api+ "/index.php/";
    


    return new Promise((resolve, reject) => {        
        //console.log(URL_SERVICIOS+type)
        fetch(URL_SERVICIOS+type)   
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           reject(error);
        })
    })    
}