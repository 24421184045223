import React from 'react'
import {Card, makeStyles } from '@material-ui/core'



import CardHeader from '../components/Card/CardHeader';
import CardIcon from '../components/Card/CardIcon';
import CardBody from '../components/Card/CardBody';
import ItemAsinaturas from './ItemAsinaturas';
import CardFooter from '../components/Card/CardFooter';
import GridItem from '../components/Grid/GridItem';
import styles from "../assets/jss/dashboardStyle";


const ItemGrados = ({ListaGrados, idDocente}) => {
  const useStyles = makeStyles(styles);
      
    

  const classes = useStyles();
  

  
     const colorgrado = (key) => {
        switch(true){
            case key < 5:{
                return "warning";
            }
            case key >= 5 && key <= 9:{
                return "success";
            }
            case key > 9:{
                return "danger";
            }
                                                                   
            default:
                return "warning";
                
        }
    }

  return (
    <>
    
     {ListaGrados.map((grado) => (
               <GridItem xs={12} sm={6} md={3} className="grid_item">
                      <Card className='card_asignatura'>
                            <CardHeader color={colorgrado(grado.id_grados)} stats icon  >
                                <CardIcon color={colorgrado(grado.id_grados)}>
                                       <h4>{grado.nombre_grado} - {grado.num_grupo}</h4>
                                </CardIcon>
                                <p className={classes.cardCategory}>Nº Estudiantes</p>
                                <h3 className={classes.cardTitle}>0</h3>
                            </CardHeader>
                            <CardBody>

                                    <ItemAsinaturas id_docente={idDocente}  id_grado={grado.id_grados} id_grupo={grado.num_grupo} />
                                                                 
                            </CardBody>
                            <CardFooter stats>
                           </CardFooter>
                        </Card>
                  </GridItem>
       ))}
     
    </>
  )
}

export default ItemGrados