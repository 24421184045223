import React, { Component } from 'react';
import {PostData} from '../../services/PostData';
import './Login.css';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import LoadingScreen from 'react-loading-screen'

class Login extends Component {

  constructor(props){
      super(props);
      this.state = {
          username:'',
          
          password:'',
          redirect: false,
          error_login: false,
          changepassword: false,
          loading: false,
          loadingscr: true,
          botonenablet: false,
          admin: true
      }
      this.login = this.login.bind(this);
      this.onChange = this.onChange.bind(this);
  }

  login(event){
    event.preventDefault();
    if (this.state.username && this.state.password){
        this.setState({error_login: false, botonenablet: true, loading: true});
        PostData('login', this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              let changepwl = responseJSON.changepassword === 'N' ? true : false;
              this.setState({changepassword:  changepwl})
              sessionStorage.setItem('userData',JSON.stringify(responseJSON));
              this.setState({redirect: true, botonenablet: false, loading:false});
          }else{
            console.log("Error login");
            this.setState({error_login: true, loading:false, botonenablet:false});
             
        }
      })
      
    }

  }

  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    
    let message_error = <div></div>;


   if (this.state.error_login)
        message_error =  <span className="error-login text-danger"> * <strong>Error</strong> - Datos de ingreso no válidos, por favor verifique</span>

    if (this.state.redirect){
       if (this.state.changepassword){
              return (<Redirect to={'/cambiopassword'} />)
          }else{

              return (<Redirect to={'/home'} />)
          } 
          
    }
    if (sessionStorage.getItem("userData")){
      if (this.state.changepassword)
          return (<Redirect to={'/cambiopassword'} />)
      else 
          return (<Redirect to={'/home'} />)
   }

   
    const styles = theme => ({
      main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
      },
      avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
      },
      submit: {
        marginTop: theme.spacing.unit * 3,
      },
    });

    return (
      <>
      <LinearProgress  style={{display: this.state.loading ? '' : 'none' }} />

      <div className="main">
                
            <CssBaseline />

            <LoadingScreen
                    loading={this.state.loadingscr}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='/images/LogoCnotasGr.png'
                    text='...'
                  > 

                  <Paper className="paper">
                 
                    <img src="/images/LogoCnotas.png" className="avatar" alt="logo" />

                    <Typography component="h1" variant="h5">
                      Ingreso al administrador
                    </Typography>
                    {message_error}
                    <form className="formulario"  onSubmit={this.login}>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Correo electrónico</InputLabel>
                        <Input name="username" id="username" autoComplete="email" onChange={this.onChange} autoFocus />
                      </FormControl>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Contraseña</InputLabel>
                        <Input name="password" id="password" type="password" onChange={this.onChange} autoComplete="current-password" />
                      </FormControl>

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={styles.submit}
                        disabled={this.state.botonenablet}
                      >
                        Ingresar
                      </Button>
                    </form>
                  </Paper>
    
          </LoadingScreen>
    </div>
     </> 

    );
  }

  componentDidMount(){
    this.setState({loadingscr: false})
  }
}

export default Login;
