import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { withStyles, useStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Card from '../components/Card/Card'
import CardBody from '../components/Card/CardBody'
import CardHeader from '../components/Card/CardHeader'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import ItemDocente from './ItemDocente'

const Docentes = () => {

    const [listaDocentes, setListaDocentes] = useState([]);
    const [loading, setLoading] = useState(false)
    const [correo, setCorreo] = useState("")
    const [password, setPassword] = useState("")
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setCorreo("")
        setPassword("")
        setOpen(false);
    };


    useEffect(() => {
        listado_alumno()
    }, [])
    
      const listado_alumno = () => {

        const datos = {

        }
        setLoading(true)
        PostData('docentes/lista/', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                setListaDocentes(responseJSON.docentes);
                setLoading(false)
            }
        });

      }

      const crearUsuario = (id_docente) => {
        const datos = {
            id_docentes: id_docente,
            id_usuarios: 0
        }
        setLoading(true)
        PostData('docentes/crearusuario/', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){            
                setCorreo(responseJSON.email)
                setPassword(responseJSON.password)
                setLoading(false)
                handleClickOpen()
            }
        });

      }

      const irAGrados = () => {
        
      }

  return (
    <>
    <Container maxWidth="lg">

        <Card>
            <CardHeader color="warning">
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                    <h4 className="H4tabla" >Listado de Docentes</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/`} color="primary"> Regresar </Button>
                    </Grid>
                </Grid>
            </CardHeader>
            <CardBody>
                <LinearProgress style={{visibility: loading ? 'visible' : 'hidden' }} />
                
                <TableContainer component={Paper}>
                    <Table className="content-table" aria-label="customized table">
                              <TableHead className="AltoFila">
                                <TableRow  className="AltoFila "> 
                                  <TableCell key="{th0}" className="fontTabla FilaEncabezadoTabla " width="5%" >Código</TableCell>
                                  <TableCell key="{th1}"className="fontTabla FilaEncabezadoTabla" width="30%" align="left">Nombre del docente</TableCell>
                                  <TableCell key="{th2}"className="fontTabla FilaEncabezadoTabla" width="20%" align="left">Teléfonos</TableCell>
                                  <TableCell key="{th3}"className="text-center fontTabla FilaEncabezadoTabla " width="20%" align="left">E-mail</TableCell>
                                  <TableCell key="{th4}"className="text-center fontTabla FilaEncabezadoTabla " width="20%" align="left">Op</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                
                                  <ItemDocente listaDocentes={listaDocentes} crearUsuario={crearUsuario} irAGrados={irAGrados} />
                                    
                              </TableBody>
                    </Table>
                </TableContainer>
            </CardBody>
        </Card>
        </Container>      
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Creación de Usuario</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
               <div><strong>Correo:</strong> {correo} </div>
               <div><strong>Contraseña:</strong> {password}  </div>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Aceptar
            </Button>
            </DialogActions>
      </Dialog>
    </>
  )
}

export default Docentes