import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './Pages/Login/Login';
import Home from './layouts/Home';


const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={Login} />
            <Route path='/login' component={Login} />
            <Route path='/home' component={Home} />        
            <Route path='*' component={Login} />
            
        </Switch>
    </BrowserRouter>
);

export default Routes;