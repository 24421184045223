import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, Dialog, AppBar, Toolbar, IconButton, Container, Card, Tooltip, colors} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
//import Paper from '@material-ui/icons/Pause';
import Paper from '@material-ui/core/Paper';
import { GetData } from '../services/GetData';
import CloseIcon from '@material-ui/icons/Close';
import { PostData } from '../services/PostData';
import SearchIcon from '@material-ui/icons/Search';
import { NivelacionInput } from '../components/NivelacionInput';
import {Usuario} from '../services/Usuario'
import { NotaInput } from '../components/NotaInput';

const NotasFinalPeriodo = () => {    
    
    const [Grado, setGrado] = React.useState([]);
    const [Loading, setLoading] = React.useState(false);
    const [LoadingAsig, setLoadingAsig] = React.useState(false);
    const [Alumnos, setAlumnos] = useState([]);
    const [promedios, setPromedios] = useState({});
    const [ListaGrados, setListaGrados] = useState([]);
    const [ListaAsignaturas, setListaAsignaturas] = useState([]);
    const [Asignatura, setAsignatura] = useState(0);
    const [UrlImpresion, setUrlImpresion] = useState('');
    const [openImpresion, setopenImpresion] = useState(false)    
    
    //const { user: usuario } = useContext(SessionContext);
    const [Periodo, setPeriodo] = useState(1);
    const [isPeriodOpen, setisPeriodOpen] = useState(false);
    const usuario = Usuario();

   
    

    const ConsultarPlanilla = async () => {
      try {
        setLoading(true);    
    
        if (!Asignatura) {
          return () => {};
        }

        console.log("asignatura", Asignatura)
        const [grado, grupo] = Grado.split('-');
        const datos = {
          cod_grado: grado,
          id_grupo: grupo,
          id_asignatura: Asignatura,
        };
    
        setAlumnos([]);
        const result = await GetData('matriculas/lisaniveladosgrado/' + grado + '/' + grupo + '/' + Asignatura + '/' + Periodo);
        const responseJSON = result;
    
        setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_calificaciones.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&periodo=' + Periodo + '&token=' + usuario.token);
    
        if (responseJSON.error === false) {
            setAlumnos(responseJSON.alumnos);
            setLoading(false);
        } else {
          console.log("Error al cargar las asignaciones");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
  
       const StyledTableCell = styled(TableCell)(({ theme }) => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        body: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      })); 
      
      const calcularPromedio = (num, nota, row, index) => {

        const notas = [row.nota_indic1, row.nota_indic2, row.nota_indic3, row.nota_indic4];
        
        switch (num){
            case 1:
                notas[0] = nota;
                row.nota_indic1 = nota;
                break;
            case 2:
                notas[1] = nota;
                row.nota_indic2 = nota;
                break;
            case 3:
                notas[2] = nota;
                row.nota_indic3 = nota;
                break;
            case 4:
                notas[3] = nota;
                row.nota_indic4 = nota;
                break;

        }
        const notasValidas = notas.filter(nota => nota !== '');
        const promedio = notasValidas.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / notasValidas.length || 0;       
        document.getElementById(`promedio_${index}`).innerHTML = promedio.toFixed(2);
        const cell = document.getElementById(`promedio_${index}`);
        if (promedio < 3)
            cell.classList.add('texto-rojo');
        else
            cell.classList.remove('texto-rojo');    

      }

      function calcularPromedioCelda (row) {
          const notas = [row.nota_indic1, row.nota_indic2, row.nota_indic3, row.nota_indic4];
          const notasValidas = notas.filter(nota => nota !== '');
          const promedio = notasValidas.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / notasValidas.length || 0;       
          return promedio.toFixed(2);
      }

      const focusNextInput = (currentInputId) => {
        const currentIndex = parseInt(currentInputId.replace('txt', ''));
        const nextIndex = currentIndex + 1;
        const nextInput = document.getElementById(`txt${nextIndex}`);
        if (nextInput) {
          nextInput.focus();
        }
        
      };
      
      const handleCloseImpresion = () => {
        setopenImpresion(false);
      };

      const AbrirPlanillaFinal = () => {
            const [grado, grupo] = Grado.split('-');

            setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_final.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&token=' + usuario.token);
            setopenImpresion(true)
      }
      const AbrirPlanilla = () => {
            const [grado, grupo] = Grado.split('-');
            setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_calificaciones.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&periodo=' + Periodo + '&token=' + usuario.token);
            setopenImpresion(true)
      }
  
     
      useEffect(() => {
            GetData("asignaciones/listgradosadmin/", usuario).then((result) => {
              let responseJSON = result;
              if (responseJSON.error === false) {
                  const datos = responseJSON.grados;
                  setListaGrados(datos)
                  setGrado(datos[0].cod_grado_grupo);
              }
            })
       
      }, [])

      useEffect(() => {
    
        if (Grado.length > 0){
            const [grado, grupo] = Grado.split('-');
            setAsignatura([]);
            setLoadingAsig(true)
            GetData("asignaciones/listasignagradogrupo/" + grado + '/' + grupo+'/', usuario).then((result) => {
              let responseJSON = result;
              if (responseJSON.error === false) {
                  const datos = responseJSON.asignaturas;
                  setListaAsignaturas(datos)
                  setAsignatura(datos[0].id_asignatura);
                  setLoadingAsig(false)
              }
            })
      }else
      return () => {}; // Función de limpieza vacía

    
      }, [Grado])
         
       
  return (
    <> 
         <Container maxWidth="lg">

                    
              <Card style={{marginTop: '20px', marginBottom: '20px', padding: '5px'}}>
                    <Grid container alignItems="center" >
                         <Grid item xs={12} style={{color: '#fff', padding: '5px', background: 'linear-gradient(90deg, rgba(9,53,121,1) 60%, rgba(138,184,228,1) 100%)',}}>
                         <div  >
                             <Typography variant="h6" color="inherit">Nota Final Período</Typography> 
                          </div> 
                         </Grid> 
                         <Grid item xs={2}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="sel_grado_grupo">Grados</InputLabel>
                              <Select
                                labelId="sel_grado_grupo"
                                id="sel_grado_grupo"
                                value={Grado}
                                onChange={(e) => setGrado(e.target.value)}
                                label="Grado"
                                
                              >
                                {ListaGrados.map((grado,index) => (
                                  <MenuItem key={index} value={grado.cod_grado_grupo}>
                                    {grado.nombre_grado} - 0{grado.id_grupo}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          
                          <Grid item xs={4}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="sel_asignaturas">Asignaturas</InputLabel>
                              <Select
                                labelId="sel_asignaturas"
                                id="sel_asignaturas"
                                value={Asignatura}
                                onChange={(e) => setAsignatura(e.target.value)}
                                label="Periodos"
                                disabled={LoadingAsig}
                              >
                                 {ListaAsignaturas.map((asig, index) => {
                                      const nombreAsignatura = asig.nombre_asignatura.split('(')[0].trim();
                                      return (
                                        <MenuItem key={`asig_${index}`} value={asig.id_asignatura}>
                                          {nombreAsignatura}
                                        </MenuItem>
                                      );
                                    })}
                              </Select>
                              {LoadingAsig && <LinearProgress />}
                            </FormControl>  
                          </Grid>
                          <Grid item xs={2}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="sel_periodo">Periodo</InputLabel>
                              
                              <Select
                                labelId="sel_periodo"
                                id="id_periodo"
                                value= {Periodo}
                                onChange={(e)=> setPeriodo(e.target.value)}
                              >
                                  <MenuItem key={1} value={1}>Periodo 1</MenuItem>
                                  <MenuItem key={2} value={2}>Periodo 2</MenuItem>
                                  <MenuItem key={3} value={3}>Periodo 3</MenuItem>
                                  <MenuItem key={4} value={4}>Periodo 4</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} sx={{paddingTop: '17px'}}>                            
                              <Button variant="outlined" onClick={()=> ConsultarPlanilla()} size="small" startIcon={<SearchIcon />}>Consultar</Button>                                                       
                          </Grid>
                          
                    </Grid>
                    {Loading && <LinearProgress  />}
                    <TableContainer component={Paper} style={{marginTop:'5px'}}>
                    <Table size="small" aria-label="customized table">
                        <TableHead className='headerTable'>
                        <TableRow style={{backgroundColor: 'brown'}}>
                            <StyledTableCell  width="50px" style={{color: '#fff', fontWeight: 'bold'}}>N°</StyledTableCell>
                            <StyledTableCell align="left" style={{color: '#fff', fontWeight: 'bold'}}>Alumno</StyledTableCell>
                            <StyledTableCell align="center" width="100px" style={{color: '#fff', fontWeight: 'bold'}}>Nota Final</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {Alumnos.map((row, index) => (
                            <StyledTableRow key={row.id_matricula} >
                            <StyledTableCell component="th" scope="row" width="50px">
                                {index+1}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.nombres_apellidos_al}</StyledTableCell>
                            <StyledTableCell align="center" width="70px">
                                <NotaInput id={`txt${index * 5 + 3}`} Grado={Grado}  idAsignatura={Asignatura} Periodo={Periodo} id_matricula={row.id_matricula} usuario={usuario} focusNextInput={focusNextInput} value={row.nota_final} />

                            </StyledTableCell>                                                  
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                 
                    </Card>
        </Container>

        <Dialog
                    fullScreen
                    open={openImpresion}
                    onClose={handleCloseImpresion}
                    
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseImpresion}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Button color="inherit" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <div>
                        <iframe src={UrlImpresion} title='Imprimir Planilla' className="inframemax">
                        </iframe> 
                    </div>
                </Dialog>
    </>
  )
}

export default NotasFinalPeriodo