import React, { Component } from 'react';
import {GetData} from '../services/GetData';
import {PostData} from '../services/PostData';
import Dialog from '@material-ui/core/Dialog';

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import CustomInput from "../components/CustomInput/CustomInput";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import CardFooter from "../components/Card/CardFooter.js";
import DialogContent from '@material-ui/core/DialogContent';

import Button from "../components/CustomButtons/Button.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';

class DetalleMatricula extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    
    this.state = {
      id_matricula : props.id_matricula,
      datos_estudiante: [],
      id_estado_matricula: 1,
      lista_indicadores: <tr><td></td></tr>,
      formulario: null,
      show: false,
      loading: false
    };
    this.datos_matricula = this.datos_matricula.bind(this);
    this.formulario_estudiante = this.formulario_estudiante.bind(this);
  }

  handleChange = (e) => {
    
    let campo = e.target.name;
    let valor = e.target.value;

    const datos_estudiante = this.state.datos_estudiante;

    

    datos_estudiante[campo] = valor
    
    this.setState({ datos_estudiante: datos_estudiante, id_estado_matricula:  valor}, () => {
        console.log(this.state.id_estado_matricula)
    });

};

  guardar(){
 
     let datos = {
         id_matricula : this.state.id_matricula,
         id_estado_matricula: this.state.id_estado_matricula
     }
     this.setState({loading: true})
     PostData('matriculas/savecondicion', datos).then ((result) =>{
         let responseJSON = result;
         if (responseJSON.error === false){           

             
             this.setState({loading: false})
             

         }else{
           console.log("Error No se pudo guardar");
       }
 
     })
 }

  sexo_estudiante(sexo){
        if(sexo === "M")
           return "Masculino";
        else
           return "Femenino";    
  }

  calcular_edad(fecha_nac){    
    console.log(fecha_nac);
    let fechanac = fecha_nac.split("-");
    let anio_nac = fechanac[0];
    let mes_nac = fechanac[1];
    let dia_nac = fechanac[2];

    let today_date = new Date();
    let today_year = today_date.getFullYear();
    let today_month = today_date.getMonth();
    let today_day = today_date.getDate();
    let age = today_year - anio_nac;
  
    if (today_month < (mes_nac - 1)) {
      age--;
    }
    if (((mes_nac - 1) === today_month) && (today_day < dia_nac)) {
      age--;
    }
    console.log("edad " + age);
    return age;
  } 

  formulario_estudiante(){
      let formulario = 
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput
                  labelText="Códgio"
                  id="id_matricula"
                  defaultValue={this.state.datos_estudiante.id_matricula}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={10}>
                <CustomInput
                  labelText="Nombre del estudiante"
                  id="nombres_apellidos_al"
                  defaultValue={this.state.datos_estudiante.nombres_apellidos_al}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Tipo Doc"
                  id="nombre_documento"
                  defaultValue={this.state.datos_estudiante.nombre_documento}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="No. Documento"
                  id="num_documento_al"
                  defaultValue={this.state.datos_estudiante.num_documento_al}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Ciudad"
                  id="municipio"
                  defaultValue={this.state.datos_estudiante.municipio}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>
           
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="F. Nacimiento"
                  id="fecha_nacimiento_al"
                  defaultValue={this.state.datos_estudiante.fecha_nacimiento_al}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput
                  labelText="Edad"
                  id="country"
                  defaultValue={this.calcular_edad(this.state.datos_estudiante.fecha_nacimiento_al)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Sexo"
                  id="sexo"
                  defaultValue={this.sexo_estudiante(this.state.datos_estudiante.sexo)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Seguro"
                  id="seguro_alumno"
                  defaultValue={this.state.datos_estudiante.seguro_alumno}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>                          
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Teléfono"
                  id="company-disabled"
                  defaultValue={this.state.datos_estudiante.telefono_al}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Dirección"
                  id="username"
                  defaultValue={this.state.datos_estudiante.direccion_al}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Sisbem"
                      id="sisbem"
                      defaultValue={this.state.datos_estudiante.sisbem}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  <FormControl className="width100" > 
                  <InputLabel id="demo-simple-select-label">Estado Matricula</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      name="id_estado_matricula"
                      value={this.state.id_estado_matricula}
                      onChange={this.handleChange}
                      formControlProps={{
                        fullWidth: true
                      }}
                    >
                      <MenuItem value={1}>Activo</MenuItem>
                      <MenuItem value={2}>Retirado</MenuItem>
                    </Select>
                    </FormControl>     
                  </GridItem>             
                 
            </GridContainer>
            
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel style={{ color: "#AAAAAA" }}>Observaciones</InputLabel>
                <CustomInput
                  labelText=""
                  id="about-me"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 3
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>



    this.setState({formulario: formulario, loading: false});
  }

  datos_matricula(){
          this.setState({loading: true});
          GetData('matriculas/datosmatricula/' + this.state.id_matricula, this.state).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                 
                this.setState({datos_estudiante: responseJSON.matricula, id_estado_matricula: responseJSON.matricula.id_estado_matricula}, () => {
                      this.formulario_estudiante();
                });
                
            }else{
              console.log("Error al cargar las aginaciones");
            }
        })
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = () => {
    
    this.setState({ show: !this.state.show }, () =>{
        if (this.state.show)
            this.datos_matricula();
    });
  }

  render() {
    return (
      <>
        <Link href={"#"} variant="primary" title="Ver Datos del Alumno" onClick={this.handleShow}>
           <span class="material-icons">
              assignment_ind
          </span>
        </Link>
        
        <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.show}>
                       

              <DialogContent>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card>
                        <CardHeader color="primary">
                          <h4 className="H4tabla" >Matricula Nº {this.state.datos_estudiante.num_matricula}</h4>
                          <p className="Ptabla" >Editar matricula estudiante</p>
                        </CardHeader>

                            <LinearProgress style={{visibility: this.state.loading ? 'visible' : 'hidden' }} />
                            {this.state.formulario}

                      </Card>
                    </GridItem>
                </GridContainer>
        
                <CardFooter>
                  <Button color="secundary" onClick={this.handleShow} >Cerrar</Button>
                  <Button color="primary" onClick={() => this.guardar()}>Actualizar</Button>
                </CardFooter>

              </DialogContent>
        </Dialog>
        
      </>
    );
  }

  componentDidMount() {    
      
  }  
}

export default DetalleMatricula;
