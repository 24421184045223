import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function MessageAlert(props) {
  const classes = useStyles();
  const vertical = "top";
  const horizontal = "center";



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.show_alert(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={props.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.typo}>
          {props.mensaje}
        </Alert>
      </Snackbar>

    </div>
  );
  
}