import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import {GetData} from '../services/GetData';

import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';


import { withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { Usuario } from '../services/Usuario';
import {URL_INFORMES} from '../services/UrlWebServis';

class Consolidado extends Component {


  constructor(props){
      
      const carp_api = sessionStorage.getItem('url_api_txt');  
      //console.log(props.datosusuario)
      const url_informes = URL_INFORMES.replace("$api",carp_api)
      super(props);
      this.state = {
          usuario_session: Usuario(),
          id_grado: props.match.params.idGrado,      
          id_grupo: props.match.params.idGrupo,
          url_informes: url_informes,
          id_periodo: 0,
          nom_grado: null,
          nombre_alumno: null,
          promedio_gen: 0,
          lista_materias: null,
          lista_periodos: [],
          lista_informe_alumno: null,
          loading: false
      }    
      
      
      this.fun_info_alumno = this.fun_info_alumno.bind(this);
      this.info_grado = this.info_grado.bind(this);
      this.lista_alumnos_grado = this.lista_alumnos_grado.bind(this);
      this.periodo_actual = this.periodo_actual.bind(this);
      this.formato_celda = this.formato_celda.bind(this);
  }

   info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              nom_grado: nom_grado
          });
      }
    });    
  } 

  fun_info_alumno(){

    GetData('matriculas/datosmatricula/' + this.state.id_matricula, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
         let datos_alumno = responseJSON.matricula[0];  
       
        this.setState({
          nombre_alumno : datos_alumno.nombres_apellidos_al
          }, () => {
               // this.lista_materias();
          });
      }
    });  
  }

  periodo_actual(){
    GetData('periodos/actual/', this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
        let periodo = responseJSON.periodo;  
        this.setState({id_periodo: periodo}, () => {
            this.listar_periodos()  
            this.lista_alumnos_grado();
        });
      }
    });
   
}

listar_periodos(){
  let periodos_list = [];

  for (let i = 1; i <= this.state.id_periodo; i++)
      periodos_list.push(i);

  const lista = periodos_list.map((item, index) =><MenuItem key={index} value={item}>Periodo {item}</MenuItem>);

  this.setState({lista_periodos: lista})

}

  listado_materias(materias){

      const StyledTableCell = withStyles(theme => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);

      return  materias.map(
             (item, index) =><><StyledTableCell key={`th2${index+2}`} width="5%" className="text-center fontTabla" >{index}</StyledTableCell >
                  </>
             );
  }

  formato_celda(key, valor){
      
      if (!isNaN(valor)){
          if (key > 1)
              return parseFloat(valor).toFixed(1);
          else
              return valor;    
      }else
          return valor;    
  }

  celdas_tabla(fila, id_estado){
    let retorno = [];

    const StyledTableCell = withStyles(theme => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);        

    Object.keys(fila).forEach(function(key) {
          let caption = fila[key]
          
          let clase = "";

          if (key !== "1"){
              clase = "text-center fontTabla ";
          }else{
              clase = "text-left fontTabla ";
          }

          if (parseInt(this.state.id_grado) >= 5){
              if (key > 1){
                 if (parseFloat(caption) < 3)
                    clase += "nota_perdida";
              }
          }
          
          if (parseInt(key) <= 1 && parseInt(id_estado) > 1){
              clase += "nota_perdida";
          }

        retorno.push(<StyledTableCell key={`td2${key}`} className={clase} >{this.formato_celda(key, caption)}</StyledTableCell > );

    }.bind(this))
    
    
    return  retorno;
  }
  listar_alumnos_notas(datos){


    

    const StyledTableRow = withStyles(theme => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.default,
        },
      },
    }))(TableRow);

    

     return  datos.map(
      (item, key) =><StyledTableRow hover  key={item.id_alumnos} className="TextoTabla"> 
              {this.celdas_tabla(item, this.state.matriculas[key].id_estado_matricula)}
            </StyledTableRow >);

   }

  lista_alumnos_grado(){


        
        let id_grado = this.state.id_grado;
        let id_grupo = this.state.id_grupo;

        const StyledTableCell = withStyles(theme => ({
          head: {
            color: theme.palette.common.white,
          },
          body: {
            fontSize: 14,
          },
        }))(TableCell);  
        this.setState({loading: true});
        GetData('informespdf/consolidadperiodo/' + id_grado + '/' + id_grupo + '/' + this.state.id_periodo + "/"+this.state.usuario_session.id_usuario+"/"+this.state.usuario_session.token, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
            
            let datos = responseJSON.datos;
            let matriculas = responseJSON.matriculas;
            let materias = responseJSON.materias;
              
            this.setState({matriculas: matriculas});

            let columnaPro = <StyledTableCell key="{th99}" className="text-center fontTabla titulotablacon" width="5%" align="left">Prom</StyledTableCell>

            if (this.state.id_grado < 5)
                columnaPro = "";

            let header = <Table className="MargenButton MargenSuperior" >
                              <TableHead className="AltoFila">
                                <TableRow  className="AltoFila"> 
                                  <StyledTableCell key="{th20}" className="text-center fontTabla titulotablacon" width="5%" >Código</StyledTableCell>
                                  <StyledTableCell key="{th21}" className="text-center fontTabla titulotablacon" width="45%" align="left">Nombre del estudiante</StyledTableCell>                                  
                                  {this.listado_materias(materias)}
                                  {columnaPro}
                                  
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                
                                   {this.listar_alumnos_notas(datos)}
                                    
                              </TableBody>
                          </Table>


          let retorno = [];
              Object.keys(materias).forEach(function(key) {
              retorno.push(<><span className="materias fontTabla" key={`span${key}`}>(<strong>{key}</strong>) {materias[key]}</span> </>
                    );
              });
              
           
           
            this.setState({lista_alumnos_grado: header, lista_materias: retorno}, () => {
                this.setState({loading: false});
            })
          }
           
        });

    
  }

  cambiar_periodo = (e) => {
        this.setState({id_periodo: e.target.value}, () =>{
          this.lista_alumnos_grado();
      });
  }

 
  render() {
    
    return (
      
      <React.Fragment>
                    
      <Container maxWidth="lg">

      <Card>
            <CardHeader color="warning">
              <Grid container spacing={2}>
                  <Grid item xs={10}>
                  <h4 className="H4tabla" >Consolidado Período {this.state.id_periodo} </h4>
                  <p className="Ptabla" >
                      <strong>Grado:</strong> {this.state.nom_grado} - 0{this.state.id_grupo} 
                  </p>
                  </Grid>
                  <Grid item xs={2}>
                      <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/`} color="primary"> Regresar </Button>
                  </Grid>
               </Grid>
            </CardHeader>
            <CardBody>
                 <span>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                     <strong  className="ml-3">Período del consolidado:</strong>  
                      <FormControl className="ml-3 sp_select_periodo">
                        
                        <Select
                          labelId="label-periodo"
                          id="id_periodo"
                          value= {this.state.id_periodo}
                          onChange={this.cambiar_periodo}
                        >
                         { this.state.lista_periodos }
                        </Select>
                      </FormControl>
                    </Grid>
                      <Grid item xs={5}>
                      <strong  className="ml-3">Consolidados adicionales:</strong>  
                        <FormControl className='ml-3 sp_select_periodo'>
                        
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          value="0"
                        >
                          <MenuItem value={0}>Seleccionar</MenuItem>
                          <MenuItem value={1}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `consolidados.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Por Período</a></MenuItem>
                          <MenuItem value={3}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `consolidados_nive.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Por Período y Nivelación</a></MenuItem>
                          <MenuItem value={4}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `consolidados_puesto.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Por Período y Puesto</a></MenuItem>
                          <MenuItem value={5}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `consolidados_sum.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Período Acumulado Total</a></MenuItem>
                          <MenuItem value={6}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `consolidados_prom.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Promedio por Período</a></MenuItem>
                          <MenuItem value={7}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `consolidados_excelpro.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Excel Calculo Promedio</a></MenuItem>
                        </Select>
                      </FormControl>
                      </Grid>
                    </Grid>
                  </span>
                  

                  <Divider className="mr-5" />
                  <Alert severity="success">
                          {this.state.lista_materias}
                  </Alert>
                  
                  <Divider light className="divider_espacio" />
                  <LinearProgress style={{visibility: this.state.loading ? 'visible' : 'hidden' }} />

                      {this.state.lista_alumnos_grado}       
            </CardBody>
                      
          </Card>
          </Container>    
    </React.Fragment>
   

    );
  }

  componentDidMount() {
    this.info_grado();
    this.periodo_actual();
    
    

  }    

}

export default Consolidado;
