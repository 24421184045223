import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import DetallePeriodo from './DetallePeriodo';
import {GetData} from '../services/GetData';
import MessageAlert from '../messages/MessageAlert'

class Periodos extends Component {

    constructor(props){
      
        super(props);
        this.state = {
            listaperiodos: [],
            loading: false,
            openmenssaje: false

        }
        this.cargar_periodos = this.cargar_periodos.bind(this);
        
    }

    show_alert(state){
        this.setState({openmenssaje: state});
        if (state === false){
            window.location.reload();
        }
    }

    cargar_periodos(){
        this.setState({loading: true})    
        GetData('periodos/todos/', this.state).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){

                let periodos = responseJSON.periodos;
                
                let listaperiodos =  periodos.map(
                                         (periodo, key) => <DetallePeriodo key={key} periodo={periodo} actual={periodo.periodo_actual} show_alert={this.show_alert.bind(this)}  />
                                    )
                
                                    this.setState({listaperiodos}, () => {
                                        this.setState({loading: false})
                                    });

            }
        });

    }

    render() {
    
        return (
          
          <React.Fragment>
                    <MessageAlert open={this.state.openmenssaje} show_alert={this.show_alert.bind(this)} mensaje="Datos Guardados" typo="success"/>
                    <Container maxWidth="lg">

                    <Card>
                        <CardHeader color="warning">
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                <h4 className="H4tabla" >Configurar Períodos</h4>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/`} color="primary"> Regresar </Button>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <LinearProgress style={{visibility: this.state.loading ? 'visible' : 'hidden' }} />
                            {this.state.listaperiodos}

                         </CardBody>
                    </Card>
                   </Container>         


          </React.Fragment>
        )
    }

    componentDidMount() {
        this.cargar_periodos();    
      }    

} 

export default Periodos;