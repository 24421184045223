import React from 'react';
import {Link} from 'react-router-dom';
import {URL_INFORMES}  from '../../../services/UrlWebServis';
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import CardBody from "../../../components/Card/CardBody.js";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CheckIcon from '@material-ui/icons/Check';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/dashboardStyle";
import GridItem from "../../../components/Grid/GridItem.js";
import purple from '@material-ui/core/colors/purple';
import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Usuario } from '../../../services/Usuario';



const Grado = ({color, id_grado, id_grupo, grado, grupo, n_studen, num_desert, num_asig, porc_notas, alumnos_mora, periodo_actual, tipo_usuario}) => { 

    const usuario_session =  Usuario();

    const carp_api = sessionStorage.getItem('url_api_txt');      
    const url_informes = URL_INFORMES.replace("$api",carp_api)

    const useStyles = makeStyles(styles);
      
      const ITEM_HEIGHT = 48;

    const classes = useStyles();
    const primary = purple[500]; // #F44336

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        
    };

    const admin_habilitado = parseInt(tipo_usuario) === 0 ? true : false;


    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                            <CardHeader color={color} stats icon  >
                                <CardIcon color={color}>
                                       <h4>{grado} - {grupo}</h4>
                                </CardIcon>
                                <p className={classes.cardCategory}>Nº Estudiantes</p>
                                <h3 className={classes.cardTitle}>{n_studen}</h3>
                            </CardHeader>
                            <CardBody>
                                    <h4 className={classes.cardTitle}>Detalles adicionales</h4>
                                    <p className={classes.cardCategory}>
                                        <span className={classes.successText}>
                                        <LibraryBooksIcon className={classes.upArrowCardCategory} /> {num_asig}
                                        </span>{" "}
                                        No. Asignaturas
                                    </p>                                    
                                    <p className={classes.cardCategory}>
                                        <span className={classes.successText}>
                                        <CheckIcon className={classes.upArrowCardCategory} /> {porc_notas}%                                         
                                        </span>{" "}
                                        Notas ingresadas.
                                        <LinearProgress className="progress_nota_grado" variant="determinate" value={porc_notas} color="secondary" />
                                    </p>
                                    <p className={classes.cardCategory}>
                                        <span className={classes.dangerText}>
                                        <CallReceivedIcon className={classes.upArrowCardCategory} /> {num_desert}
                                        </span>{" "}
                                        Retirados
                                    </p> 
                                    <p className={classes.cardCategory}>
                                        <span className={classes.dangerText}>
                                        <AttachMoney className={classes.upArrowCardCategory} /> {alumnos_mora}
                                        </span>{" "}
                                        En Mora
                                    </p>                                           
                                                                 
                                    </CardBody>
                                <CardFooter stats>

                                    <div className={classes.stats}>
                                    { (parseInt(periodo_actual) < 4)
                                        && <Button type="button" variant="contained" 
                                               /*  style={{display: admin_habilitado ? '' : 'none' }}  */
                                                className="MargenBoton" 
                                                size="small"                            
                                                component={Link}
                                                to={`/home/consolidado/${id_grado}/${id_grupo}/`}               
                                                color={primary}>Consolidado</Button> 
                                    }            
                                    { (parseInt(periodo_actual) === 4)
                                        && <Button type="button" variant="contained" 
                                               /*  style={{display: admin_habilitado ? '' : 'none' }}  */
                                                className="MargenBoton" 
                                                size="small"                            
                                                component={Link}
                                                to={`/home/consolidadoresumen/${id_grado}/${id_grupo}/`}               
                                                color={primary}>Consolidado</Button> 
                                    }                                      

                                    { (parseInt(periodo_actual) < 4)
                                        &&  <a href={`${url_informes}/informes.php?grado=${id_grado}&grupo=${id_grupo}&periodo=${periodo_actual}&token=${usuario_session.token}`} target="black" >
                                                
                                            <Button type="button" variant="contained" 
                                                    style={{display: admin_habilitado ? '' : 'none' }} 
                                                    className="space_left" 
                                                    size="small"     
                                                    target="black"
                                                    color={primary}>Informe</Button></a>    
                                    }     
                                    { (parseInt(periodo_actual) === 4)
                                        &&  <a href={`${url_informes}/informes-final.php?grado=${id_grado}&grupo=${id_grupo}&token=${usuario_session.token}`} target="black" >
                                                
                                        <Button type="button" variant="contained" 
                                                style={{display: admin_habilitado ? '' : 'none' }} 
                                                className="space_left" 
                                                size="small"     
                                                target="black"
                                                color={primary}>Informe</Button></a>
                                    }
                                    </div>             
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>                   

                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '27ch',
                                        },
                                        }}
                                    >
                                        
                                        <MenuItem key={0}  onClick={handleClose}  component={Link} to={`/home/listadoalumnos/${id_grado}/${id_grupo}`}>
                                               Lista de Alumnos 
                                        </MenuItem>
                                        <MenuItem key={0}  onClick={handleClose}  >
                                              <a href={`${url_informes}/consolidados-final.php?grado=${id_grado}&grupo=${id_grupo}&token=${usuario_session.token}`} target="black" >
                                               Consolidado Final 
                                               </a>
                                        </MenuItem>
                                        <MenuItem key={0}  onClick={handleClose}  >
                                              <a href={`${url_informes}/certificado.php?grado=${id_grado}&grupo=${id_grupo}&token=${usuario_session.token}`} target="black" >
                                               Certificados 
                                               </a>
                                        </MenuItem>
                                        <MenuItem key={0}  onClick={handleClose}  >
                                              <a href={`${url_informes}/certificado-comport.php?grado=${id_grado}&grupo=${id_grupo}&token=${usuario_session.token}`} target="black" >
                                               Cert. Comportamiento
                                               </a>
                                        </MenuItem>
                                        <MenuItem key={0}  onClick={handleClose}  >
                                              <a href={`${url_informes}/listado_alumnos_puestos.php?grado=${id_grado}&grupo=${id_grupo}&token=${usuario_session.token}`} target="black" >
                                               Listado Puestos Final
                                               </a>
                                        </MenuItem>
                             
                                        
                                    </Menu>
                                </CardFooter>
                        </Card>
            </GridItem>            
    </>);
}

export default Grado;