import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import {PostData} from '../services/PostData';
import Moment from 'moment';
import 'moment/locale/es';



class DetallePeriodo extends Component {

    constructor(props){
      
        super(props);
        
        const {id_periodo, fecha_inicio, fecha_cierre, fecha_inicio_niv, fecha_cierre_niv, entrega, periodo_actual} = props.periodo;
        let opentrega = parseInt(entrega);
        let p_actual = parseInt(periodo_actual);

        

        this.state = {
                id_periodo:id_periodo,
                fecha_inicio : fecha_inicio,
                fecha_cierre : fecha_cierre,
                fecha_inicio_niv : fecha_inicio_niv,
                fecha_cierre_niv : fecha_cierre_niv,
                entrega : opentrega,
                periodo_actual: p_actual,
                dias_faltantes_cierre: 0,

        }
        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.guardar = this.guardar.bind(this);
        this.diasrestantes = this.diasrestantes.bind(this);
    }

    guardar(){
       // this.props.show_alert(false);
        PostData('periodos/save', this.state).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){           

                this.props.show_alert(true);
                

            }else{
              console.log("Error No se pudo guardar");
          }
    
        })
    }

    onChange(e){
        this.setState({[e.target.name]: e.target.value}, () => {
            this.diasrestantes();
        });
        
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.checked });
       
    };

    diasrestantes(){
        let dias_faltantes_cierre = Moment(this.state.fecha_cierre, "YYYYMMDD").endOf('day').fromNow();
        this.setState({dias_faltantes_cierre})

    }
    

    render() {
        
        return (
          
          <React.Fragment>
                   
                    <Grid container spacing={5}  className={this.state.periodo_actual === "1" ? "rowselect": ""}>

                    <Grid item xs={1} >
                        
                            <h5 className="center">Periodo</h5>
                            <p className="center">{this.state.id_periodo}</p>
                        
                    </Grid>
                    <Grid item xs="auto">
                        <h5>Fecha Inicio</h5>
                        <p>
                        <input type="date"
                            name="fecha_inicio"
                            value={this.state.fecha_inicio}
                            onChange={this.onChange}
                            />
                        </p>    
                    </Grid>
                    <Grid item xs="auto">
                    <h5>Fecha Cierre</h5>
                        <p>
                        <input type="date"
                            name="fecha_cierre"
                            value={this.state.fecha_cierre}
                            onChange={this.onChange}
                            />
                        </p>   
                    </Grid>
                    <Grid item xs="1">
                        <h5 className="center">Dias F.</h5>
                        <p className="center">{this.state.dias_faltantes_cierre}</p>
                    </Grid>
                    
                   {/*  <Grid item xs={1}>
                        <h5>Informe</h5>
                        <p>
                        <Switch
                                checked={this.state.entrega}
                                onChange={this.handleChange}
                                name="entrega"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </p>
                    </Grid>       */}                          
                    <Grid item xs="auto">

                    <h5>Fecha Inic Nivelación</h5>
                        <p>
                        <input type="date"
                            name="fecha_inicio_niv"
                            value={this.state.fecha_inicio_niv}
                            onChange={this.onChange}
                            />
                        </p>

                    </Grid>
                    <Grid item xs="auto">

                    <h5>Fecha Fin Nivelación</h5>
                        <p>
                        <input type="date"
                            name="fecha_cierre_niv"
                            value={this.state.fecha_cierre_niv}
                            onChange={this.onChange}
                            />
                        </p>

                    </Grid>
                    <Grid item xs="1">
                        <h5 className="center">P. Actual</h5>
                        <Switch
                                checked={this.state.periodo_actual}
                                onChange={this.handleChange}
                                name="periodo_actual"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />

                    </Grid>                    
                    <Grid item xs="auto">
                    
                    <Button variant="contained" className="magin_top_periodo" color="primary" onClick={() => this.guardar()}>
                        Guardar
                    </Button>

                    </Grid>

                    </Grid>
                    <Divider />
        </React.Fragment>  
        
        )
    }

    componentDidUpdate(){
        console.log("componentDidUpdate")
        let p_actual = this.state.periodo_actual === true ? 1 : 0;
        console.log(" .... " + this.state.id_periodo + " - " + p_actual  + " !== " + this.props.periodo.periodo_actual)
    }

    
    componentDidMount(){
        this.diasrestantes();
        
    }
}

export default DetallePeriodo;