import React from 'react';
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import DateRange from "@material-ui/icons/DateRange";
import Icon from "@material-ui/core/Icon";
import Store from "@material-ui/icons/Store";
import AttachMoney from '@material-ui/icons/AttachMoney';
import MoneyOff from '@material-ui/icons/MoneyOff';
import Accessibility from "@material-ui/icons/Accessibility";
import LinearProgress from '@material-ui/core/LinearProgress';
import Update from "@material-ui/icons/Update";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../../../assets/jss/dashboardStyle";

const BardInfo = (props) => { 

    const useStyles = makeStyles(styles);

    const classes = useStyles();


    return (<>
                    <GridContainer>
                            <GridItem xs={12} sm={6} md={3}>
                            <Card>
                                <CardHeader color="info" stats icon>
                                <CardIcon color="info">
                                    <Accessibility />
                                </CardIcon>
                                <p className={classes.cardCategory}>Alumnos Matriculados</p>
                                <h3 className={classes.cardTitle}>{props.num_alumnos_matr}</h3>
                                </CardHeader>
                                
                                <CardFooter stats>
                                
                                <div className={classes.stats}>
                                    <Update />
                                    Actualizacion: {props.ultima_actualizacion}
                                </div>
                                
                                </CardFooter>
                                <LinearProgress color="secondary" style={{visibility: props.loading_datos_matr ? 'visible' : 'hidden' }} />
                            </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                            <Card>
                                <CardHeader color="danger" stats icon>
                                <CardIcon color="danger">
                                    <Icon>info_outline</Icon>
                                </CardIcon>

                                <p className={classes.cardCategory}>Retirados</p>
                                <h3 className={classes.cardTitle}>{props.num_alumnos_retir}</h3>
                                </CardHeader>
                                <CardFooter stats>
                                <div className={classes.stats}>
                                    
                                </div>
                                </CardFooter>
                            </Card>

                            
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                            <Card>
                                <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Store />
                                </CardIcon>
                                <p className={classes.cardCategory}>Docentes</p>
                                <h3 className={classes.cardTitle}>{props.num_docentes}</h3>
                                </CardHeader>
                                <CardFooter stats>
                                <div className={classes.stats}>
                                    <DateRange />
                                    12-01-201-00
                                </div>
                                </CardFooter>
                            </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                            <Card>
                                <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>content_copy</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Período</p>
                                <h3 className={classes.cardTitle}>
                                    {props.periodo_actual}
                                </h3>
                                </CardHeader>
                                <CardFooter stats>
                                <div className={classes.stats}>
                                    Fecha de cierre: {props.fecha_cierre_p} 
                                    <span className={props.p_cerrado ? "p_cerrado": "p_abierto"}>
                                         ({props.dias_faltantes_cierre})
                                    </span>
                                </div>
                                </CardFooter>
                            </Card>

                            

                            </GridItem>
                        </GridContainer>
            </>            
        );
}

export default BardInfo;