import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import {PostData} from '../services/PostData';
import {GetData} from '../services/GetData';
import {URL_INFORMES} from '../services/UrlWebServis';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {Usuario} from '../services/Usuario'

import { withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import DetalleMatricula from '../Pages/DetalleMatricula';
import Select from '@material-ui/core/Select';
import { FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';


class ListadoAlumnos extends Component {

  constructor(props){

    const carp_api = sessionStorage.getItem('url_api_txt');      
    const url_informes = URL_INFORMES.replace("$api",carp_api)

      super(props);
      this.state = {
          usuario_session: Usuario(),  
          id_grado: props.match.params.idGrado,      
          id_grupo: props.match.params.idGrupo,          
          id_periodo: 0,
          url_informes: url_informes,
          nom_grado: null,
          listado_alumnos: null,
          listado_pension: null,
          num_alumnos: 0,
          num_alumnos_mora: 0,
          num_retirados: 0,
          otra_condicion: 0,
          loading: false
      }    
      
      
      this.fun_info_alumno = this.fun_info_alumno.bind(this);
      this.info_grado = this.info_grado.bind(this);
      this.lista_alumnos_grado = this.lista_alumnos_grado.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.fun_estudiante_mora = this.fun_estudiante_mora.bind(this);
      this.obtener_periodo_actual = this.obtener_periodo_actual.bind(this);
      this.listar_periodos = this.listar_periodos.bind(this);
  }

   info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              nom_grado: nom_grado
          });
      }
    });    
  } 

  fun_estudiante_mora(){
    
    GetData('matriculas/numestudmora/'+this.state.id_grado+"/"+this.state.id_grupo, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
         let num_mora = responseJSON.num_mora;  
       
        this.setState({
          num_alumnos_mora : num_mora
        });
      }
    });
  } 

  obtener_periodo_actual(){
    
    GetData('periodos/actual/', this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
                  
        this.setState({
            id_periodo :  responseJSON.periodo
        }, () => {
            this.listar_periodos();
            this.lista_alumnos_grado();
        })
      }
    });
  }

  fun_info_alumno(){

    GetData('matriculas/datosmatricula/' + this.state.id_matricula, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
         let datos_alumno = responseJSON.matricula[0];  
       
        this.setState({
          nombre_alumno : datos_alumno.nombres_apellidos_al
          }, () => {
               // this.lista_materias();
          });
      }
    });  
  }

  colores_estado(id_estado_matricula){
      switch (id_estado_matricula){
            case "1":
                  return "color_estado_normal";
            case "2":      
                  return "color_estado_retirado";  
            default:      
                  return "color_estado_otro";  
      }
  }

  handleSelect = (e) => {
    const name = e.target.name;
    this.setState({
        ...this.state,
        [name]: e.target.value,
    });
    this.lista_alumnos_grado();
  }

  handleChange = (e) => {
      console.log(e.target.id);
      let campo = e.target.id;

      let partes = campo.split("-");
      
      let index = partes[1];
      let nombreor = partes[0];

      const listado_pension = this.state.listado_pension;
      

       
      listado_pension[index][nombreor] = e.target.checked ? 1 : 0;
      
      this.setState({ listado_pension });

  };

  listar_alumnos(datos){


    

    const StyledTableRow = withStyles(theme => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.default,
        },
      },
    }))(TableRow);

    const StyledTableCell = withStyles(theme => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell); 

     return  this.state.listado_pension.map(
      (item, key) =><StyledTableRow key={`filac${key}`}  hover  className={`TextoTabla`}> 
              <StyledTableCell key={`td1${key}`}className={`${this.colores_estado(item.id_estado_matricula)}`} >{item.num_matricula}</StyledTableCell >
              <StyledTableCell key={`td2${key}`} className={`${this.colores_estado(item.id_estado_matricula)}`} >{item.nombres_apellidos_al}</StyledTableCell >
              <StyledTableCell key={`td3${key}`} className={`${this.colores_estado(item.id_estado_matricula)}`} >{item.num_documento_al}</StyledTableCell >
              
              <StyledTableCell key={`td4${key}`} className={`${this.colores_estado(item.id_estado_matricula)}`} >{item.nombre_estado}</StyledTableCell >
              <StyledTableCell key={`td4${key}`} className={`${this.colores_estado(item.id_estado_matricula)}`} >
                    <a href={`${this.state.url_informes}/informes.php?id_matricula=${item.num_matricula}&grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&token=${this.state.usuario_session.token}`} target="black" variant="primary" title="Descargar Informe en PDF"  >
                        <span class="material-icons">
                        picture_as_pdf
                        </span>
                    </a>  
              </StyledTableCell >
              {/* <StyledTableCell key={`td4${key}`} className={`${this.colores_estado(item.id_estado_matricula)}`} >
                    <a  target="_black" href={`${URL_SERVICIOS}/pruebapdf/libropromocionalumno/${item.num_matricula}/${this.state.id_grado}/${this.state.id_grupo}/${this.state.usuario_session.token}`}  variant="primary" title="Informe Final en PDF" >
                        <span class="material-icons">
                        school
                        </span>
                    </a>  
              </StyledTableCell >   */}            
              <StyledTableCell key={`td5${key}`} className={`${this.colores_estado(item.id_estado_matricula)}`} >
                      <DetalleMatricula id_matricula={item.id_matricula} />
              
              </StyledTableCell>
            </StyledTableRow >);

   }

  lista_alumnos_grado(){

        const StyledTableCell = withStyles(theme => ({
          head: {
            color: theme.palette.common.white,
          },
          body: {
            fontSize: 14,
          },
        }))(TableCell);  
        this.setState({loading: true});

        let datos = {
            id_grado : this.state.id_grado,
            id_grupo : this.state.id_grupo
        }

        PostData('matriculas/listaalumnosgrado/', datos).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
            const listamatriculados = responseJSON.alumnos;

            this.setState({listado_pension : listamatriculados})

            const num_retirados = listamatriculados.filter(matricula => parseInt(matricula.id_estado_matricula) === 2);
            const otra_condicion = listamatriculados.filter(matricula => parseInt(matricula.id_estado_matricula) > 2);
            
            this.setState({
                num_alumnos: listamatriculados.length,
                num_retirados : num_retirados.length,
                otra_condicion: otra_condicion.length
            })
            let matriculas = responseJSON.alumnos;
        
            let listado_alumnos = <Table className="MargenButton MargenSuperior" >
                              <TableHead className="AltoFila">
                                <TableRow  className="AltoFila"> 
                                  <StyledTableCell key="{th0}" className="fontTabla FilaEncabezadoTabla" width="5%" >Código</StyledTableCell>
                                  <StyledTableCell key="{th1}"className="fontTabla FilaEncabezadoTabla" width="35%" align="left">Nombre del estudiante</StyledTableCell>
                                  <StyledTableCell key="{th2}"className="fontTabla FilaEncabezadoTabla" width="20%" align="left">Identificación</StyledTableCell>
                                  <StyledTableCell key="{th3}"className="text-center fontTabla FilaEncabezadoTabla" width="15%" align="left">Estado</StyledTableCell>
                                  <StyledTableCell key="{th3}"className="text-center fontTabla FilaEncabezadoTabla" width="15%" align="left">Informe</StyledTableCell>
                                {/*   <StyledTableCell key="{th3}"className="text-center fontTabla FilaEncabezadoTabla" width="15%" align="left">Inf. Final</StyledTableCell> */}
                                  <StyledTableCell key="{th4}"className="text-center fontTabla FilaEncabezadoTabla" width="15%" align="left">Op</StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                
                                   {this.listar_alumnos(matriculas)}
                                    
                              </TableBody>
                          </Table>


              
           
           
            this.setState({listado_alumnos: listado_alumnos}, () => {
                this.setState({loading: false});
            })
          }
           
        });

    
  }

  listar_periodos(){
    let periodos_list = [];

    for (let i = 1; i <= this.state.id_periodo; i++)
        periodos_list.push(i);

    const lista = periodos_list.map((item, index) =><MenuItem key={index} value={item}>Periodo {item}</MenuItem>);

    this.setState({lista_periodos: lista})

  }

 
  render() {
    
    return (
      
      <React.Fragment>
                    
      <Container maxWidth="lg">

      <Card>
            <CardHeader color="warning">
              <Grid container spacing={2}>
                  <Grid item xs={10}>
                  <h4 className="H4tabla" >Listado de Alumnos</h4>
                  <p className="Ptabla" >
                      <strong>Grado:</strong> {this.state.nom_grado} - 0{this.state.id_grupo} 
                  </p>
                  </Grid>
                  <Grid item xs={2}>
                      <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/`} color="primary"> Regresar </Button>
                  </Grid>
                  
               </Grid>
            </CardHeader>
            <CardBody>

                  <Alert severity="success">
                          <strong>No. Alumnos:</strong> {this.state.num_alumnos}
                          <span className="space_left_45"><strong>No. Retirados:</strong> {this.state.num_retirados}</span>
                          <span className="space_left_45">


                 
                      <strong  className="ml-3">Período:</strong>  
                      <FormControl className="ml-3 sp_select_periodo">
                        
                        <Select
                          labelId="label-periodo"
                          id="id_periodo"
                          name="id_periodo"
                          value= {this.state.id_periodo}
                          onChange={this.handleSelect}
                        >
                        {/*  <MenuItem index={1} value={1}>Periodo {1}</MenuItem>
                         <MenuItem index={2} value={2}>Periodo {2}</MenuItem> */}
                         { this.state.lista_periodos }
                        </Select>
                      </FormControl>
                      </span>  

                            {/*  Listados */}
                      <strong  className="ml-3">Listados de alumnos:</strong>  
                        <FormControl className='ml-3 sp_select_periodo'>
                        
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          value="0"
                        >
                          <MenuItem value={0}>Seleccionar</MenuItem>
                          <MenuItem value={1}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `listado_alumnos_firma.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&token=${this.state.usuario_session.token}`}>Formato Entrega de Informes</a></MenuItem>                          
                          <MenuItem value={1}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `listado_alumnos_datos.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&token=${this.state.usuario_session.token}`}>PDF Listado Estudiantes</a></MenuItem>                          
                          <MenuItem value={1}><a className="dropdown-item" target="_blank"  href={this.state.url_informes + `lisadoalumnosdatos_xls.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&token=${this.state.usuario_session.token}`}>Excel Listado Estudiantes</a></MenuItem>                          
                        </Select>
                      </FormControl>
                  </Alert>
                  <Divider light className="divider_espacio" />
                  <LinearProgress style={{visibility: this.state.loading ? 'visible' : 'hidden' }} />

                      {this.state.listado_alumnos}       
            </CardBody>
                      
          </Card>
          </Container>    
    </React.Fragment>
   

    );
  }

  componentDidMount() {
    this.info_grado();
    this.obtener_periodo_actual();
    this.fun_estudiante_mora();

  }    

}

export default ListadoAlumnos;
